import type { Agent } from "@/types/agent";
import { AgentState } from "@/types/agent";
import { LoadingSpinner } from "../ui/loading-spinner";
import PlaceholderRobot from "@/assets/images/robots/robot-1.svg";
import { ChatTopBar } from "./components/ChatTopBar/ChatTopBar";
import { ChatContent } from "./components/ChatContent";
import ChatBookmarksHistoryBtn from "./components/ChatBookmarksHistoryBtn";
import { ChatInputForm } from "./components/ChatInputForm";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { cn } from "@/lib/utils";
import type { ChatAgent } from "@/types/conversation";
import { useEffect, useState } from "react";
import { ChatFileDropOverlay } from "./components/ChatFileDropOverlay";
import { ChatAgentInfoBar } from "./components/ChatAgentInfoBar/ChatAgentInfoBar";
import { ChatKnowledge } from "./components/ChatKnowledge/ChatKnowledge";
import { domElementIds } from "@/types/dom-element-ids";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import { PredefinedQuestions } from "./components/PredefinedQuestions";
import MultiAgentsChatDialog from "../dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import { useGetUser } from "@/data/queries/useGetUser";
import { getCanCreateGroupChatWithAgent } from "./utils/getCanCreateGroupChatWithAgent";
import { useAddAgentToConversation } from "@/data/mutations/useAddAgentToConversation";
import { useNavigate } from "react-router";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";

type ChatProps = {
  agents: (ChatAgent | undefined)[];
  isLoading: boolean;
  isError: boolean;
  onBackClick?: () => void;
  onOpenMobileBookmarksHistoryPanel?: () => void;
};

export const Chat = ({ agents, isLoading, isError, onBackClick, onOpenMobileBookmarksHistoryPanel }: ChatProps) => {
  const { isMobile } = useMediaQueriesContext();
  const navigate = useNavigate();
  const { user } = useGetUser();
  const { mutate: addAgents, isPending: isPendingAddAgents } = useAddAgentToConversation();
  const { conversationDetails, isPreview, conversationId, isGroupChat, isSendingMessage } = useChatContext();

  const [isUserDragging, setIsUserDragging] = useState(false);
  const [activeAgentIndex, setActiveAgentIndex] = useState(0);
  const [isKnowledgePreviewOpen, setIsKnowledgePreviewOpen] = useState(false);
  const [isKnowledgeDialogOpen, setIsKnowledgeDialogOpen] = useState(false);
  const [isMultiAgentChatDialogOpen, setIsMultiAgentChatDialogOpen] = useState(false);

  const {
    data: conversationData,
    isLoading: isLoadingConversationData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetConversationMessages({
    conversationId,
  });

  const arePredefinedQuestionsDisabled = isLoadingConversationData || isSendingMessage;
  const isChatEmpty = conversationData?.totalMessages === 0 || !conversationData;
  const showPredefinedQuestions = !isGroupChat && isChatEmpty;

  useEffect(() => {
    if (conversationDetails?.type === "search") {
      setIsKnowledgePreviewOpen(true);
    }
  }, [conversationDetails]);

  const handleDragEnter: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsUserDragging(true);
  };

  if (isLoading) {
    return (
      <div className="grid size-full place-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-10">
        <p>There was an error when retrieving agent data.</p>
      </div>
    );
  }

  if (agents.some(agent => !agent)) {
    return (
      <div className="flex size-full flex-col items-center justify-center gap-10">
        <img src={PlaceholderRobot} className="max-h-60" alt="a smiling cartoon-like robot" />
        <p>Create agent first to see chat preview.</p>
      </div>
    );
  }

  const isAgentLoading = agents.some(
    agent => agent?.state === AgentState.AGENT_CREATING || agent?.state === AgentState.AGENT_UPDATING
  );

  const agentsFiltered = agents.filter(agent => !!agent).map(agent => agent!);
  const activeAgent = agentsFiltered[activeAgentIndex ?? 0];
  const showAiMistakeDisclaimer = !isChatEmpty;
  const canCreateMultiAgentChat =
    !isPreview &&
    agentsFiltered.length === 1 &&
    getCanCreateGroupChatWithAgent({ agent: agentsFiltered[0], userId: user?._id ?? "" });

  const handleAddAgentsToConversation = ({ agentIds, manager }: { agentIds: string[]; manager: Agent["_id"] }) => {
    const isNewConversation = !conversationId;

    if (isNewConversation) {
      navigate(getNewConversationRoute([...agentsFiltered.map(agent => agent._id), ...agentIds], manager));
    } else {
      addAgents(
        { conversationId: conversationId ?? "", agentIds },
        {
          onSuccess: () => {
            setIsMultiAgentChatDialogOpen(false);

            LocalStorageHelpers.updateLocalStorageConversation({
              conversationId,
              prevAgentIds: agentsFiltered.map(agent => agent._id),
              updatedAgentIds: [...agentsFiltered.map(agent => agent._id), ...agentIds],
            });
          },
        }
      );
    }
  };

  return (
    <>
      <div
        className={cn(
          "relative z-40 mx-auto flex size-full flex-col overflow-x-hidden",
          isAgentLoading && "pointer-events-none opacity-50"
        )}
        {...(isAgentLoading && {
          "aria-disabled": true,
          onClick: e => e.preventDefault(),
          onKeyDown: e => e.preventDefault(),
        })}
        onDragEnter={handleDragEnter}
      >
        {isUserDragging && <ChatFileDropOverlay setIsUserDragging={setIsUserDragging} />}

        <ChatTopBar
          agents={agentsFiltered}
          onBackClick={onBackClick}
          activeAgentIndex={activeAgentIndex}
          setActiveAgentIndex={setActiveAgentIndex}
          setIsKnowledgeDialogOpen={setIsKnowledgeDialogOpen}
          setIsMultiAgentChatDialogOpen={setIsMultiAgentChatDialogOpen}
        />

        {!isMobile && !isPreview && (
          <ChatAgentInfoBar
            agents={agentsFiltered}
            onOpenKnowledgePreview={() => setIsKnowledgePreviewOpen(true)}
            setIsKnowledgeDialogOpen={setIsKnowledgeDialogOpen}
            activeAgentIndex={activeAgentIndex}
            setActiveAgentIndex={setActiveAgentIndex}
          />
        )}

        <div
          id={domElementIds.CHAT_CONTAINER}
          className="flex size-full gap-6 overflow-y-auto @container/chat-container lg:px-4"
        >
          <ChatKnowledge
            agent={activeAgent}
            isPrevieOpen={isKnowledgePreviewOpen}
            setIsPreviewOpen={setIsKnowledgePreviewOpen}
            isModalOpen={isKnowledgeDialogOpen}
            setIsModalOpen={setIsKnowledgeDialogOpen}
            listBannerClassName="@2xl/chat-container:block hidden"
          />

          <div className="mx-auto flex min-w-0 max-w-[min(800px,100%)] flex-shrink flex-grow flex-col justify-center">
            <ChatContent
              agents={agentsFiltered}
              conversationData={conversationData}
              isLoadingConversationData={isLoadingConversationData}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isChatEmpty={isChatEmpty}
            />

            {isMobile && (
              <div className="flex justify-end">
                <ChatBookmarksHistoryBtn onClick={onOpenMobileBookmarksHistoryPanel} />
              </div>
            )}

            <div className={cn("rounded-xl px-2 pt-0", isChatEmpty && "bg-light-grey pb-4")}>
              <ChatInputForm
                agents={agentsFiltered}
                onOpenMultiAgentChat={() => setIsMultiAgentChatDialogOpen(true)}
                canCreateMultiAgentChat={canCreateMultiAgentChat}
                isExtended={isChatEmpty}
              />

              {showAiMistakeDisclaimer && (
                <div
                  className={cn("my-2 text-center text-xxs font-medium text-neutral-500", {
                    "opacity-0": !showAiMistakeDisclaimer,
                  })}
                >
                  AI can make mistakes
                </div>
              )}

              {showPredefinedQuestions && (
                <PredefinedQuestions
                  agent={agentsFiltered[0]}
                  disabled={arePredefinedQuestionsDisabled}
                  isWelcomeView
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <MultiAgentsChatDialog
        isOpen={isMultiAgentChatDialogOpen}
        setIsOpen={setIsMultiAgentChatDialogOpen}
        onSubmit={handleAddAgentsToConversation}
        isPendingAddAgents={isPendingAddAgents}
        initialSelectedAgentsIds={agentsFiltered.map(agent => agent._id) ?? []}
        headerText="Add Agents to Chat"
      />
    </>
  );
};
