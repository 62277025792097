import { ROUTES } from "@/constants/routes";
import { Icons } from "../ui/icons";
import { AgentHistoryNav } from "@/components/navigation/AgentHistoryNav";
import { cn } from "@/lib/utils";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { useGetUser } from "@/data/queries/useGetUser";
import { InviteTeamMemberNavBtn } from "@/components/navigation/InviteTeamMemberNavBtn";
import { Notifications } from "@/components/Notifications/Notifications";
import { SubscriptionTierButton } from "@/components/navigation/SubscriptionTierButton";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";
import { SideBarNavHeader } from "@/components/navigation/SideBarNavHeader";
import { SideBarNavUserInfo } from "@/components/navigation/SideBarNavUserInfo";
import type { NavigationItemType } from "@/components/navigation/NavigationItem";
import { NavigationItem } from "@/components/navigation/NavigationItem";

export const SideBarNav = ({
  mobileNav = false,
  onCloseMobileNav,
  isGuestUser,
}: {
  mobileNav?: boolean;
  onCloseMobileNav?: () => void;
  isGuestUser: boolean;
}) => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { data: user } = useGetUser({ enabled: !isGuestUser });
  const { navigationSectionOpen } = useLayoutContext();
  const { isMobile } = useMediaQueriesContext();

  const isSpaceHidden = user?.customer ? !workspaceDetails?.customer.isVisibleAgentsSpace : false;

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;
  const isStatsDisabled = !!(!isWorkspaceAdmin && user?.customer);

  if (!mobileNav && isMobile) {
    return null;
  }

  const navigationItems: {
    main: NavigationItemType[];
    other: NavigationItemType[];
  } = {
    main: [
      { basic: { name: "Agent Space", icon: Icons.Galaxy, to: ROUTES.community, hidden: isSpaceHidden } },
      { basic: { name: "Workspace", icon: Icons.Workspaces, to: ROUTES.workspace, needToLogIn: isGuestUser } },
    ],
    other: [
      {
        basic: {
          name: "Statistics",
          icon: Icons.ThinStats,
          to: ROUTES.stats,
          hidden: isStatsDisabled,
          needToLogIn: isGuestUser,
        },
      },
      {
        component: <Notifications />,
      },
      {
        basic: {
          name: "Help",
          icon: Icons.CircleHelp,
          to: ROUTES.help,
          needToLogIn: isGuestUser,
        },
      },
    ],
  };

  return (
    <div
      className={cn(
        `flex h-full w-[204px] flex-col gap-4 overflow-x-hidden overflow-y-scroll bg-transparent p-3 text-xs font-medium leading-5 lg:fixed lg:z-[11] lg:ml-[0px] lg:h-screen`,
        !navigationSectionOpen && `w-[62px]`,
        isMobile && "p-0 pt-8"
      )}
    >
      <div className="flex flex-col gap-10">
        {!isMobile && <SideBarNavHeader isGuestUser={isGuestUser} />}

        <div className="flex flex-col gap-2">
          {navigationItems.main
            .filter(item => !item?.basic?.hidden)
            .map((item, index) => (
              <NavigationItem item={item} key={item.basic?.name || "menu-main" + index} />
            ))}
          {!!user && <AgentHistoryNav onCloseMobileNav={onCloseMobileNav} isGuestUser={isGuestUser} />}
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-xxs font-medium text-neutral-400">Other</span>
          {navigationItems.other
            .filter(item => !item?.basic?.hidden)
            .map((item, index) => (
              <NavigationItem item={item} key={item?.basic?.name || "menu-other" + index} />
            ))}
        </div>
      </div>

      <div className="mt-auto flex flex-col items-start gap-3">
        <div className={cn("flex w-full border-b border-b-primary-100 pb-3", !user && "border-b-0 pb-0")}>
          <InviteTeamMemberNavBtn isGuestUser={isGuestUser} />
        </div>

        {user?.subscription.tier !== undefined && <SubscriptionTierButton tier={user.subscription.tier} inNav />}
        {user && <SideBarNavUserInfo isGuestUser={isGuestUser} />}
      </div>
    </div>
  );
};
