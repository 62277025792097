import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { useGetSingleConversationDetails } from "@/data/queries/useGetSingleConversationDetails";
import { useQueries } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { Chat } from "@/components/Chat/Chat";
import { cn } from "@/lib/utils";
import BookmarksHistoryPanel from "@/components/BookmarksHistoryPanel/BookmarksHistoryPanel";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { getSingleConversationAgentWithConfig } from "@/data/queries/useGetSingleConversationAgentWithConfig";
import { singlePrivateAgentWithConfigKeys } from "@/data/queries/useGetSinglePrivateAgentWithConfig";
import { useTrackAgentInteractionStats } from "@/hooks/useTrackAgentInteractionStats";
import AnimatedBookmarksHistoryWrapper from "@/components/BookmarksHistoryPanel/components/AnimatedBookmarksHistoryWrapper";
import { ChatMessageReasoning } from "@/components/Chat/components/ChatMessageReasoning/ChatMessageReasoning";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";

const Conversation = () => {
  const { isMobile } = useMediaQueriesContext();

  const [isMobileBookmarkHistoryPanelOpen, setIsMobileBookmarkHistoryPanelOpen] = useState(false);

  const { conversationId, conversationAgentIds, messageForReasoningPreview } = useChatContext();
  const { data: conversationDetails, isPending: isPendingConversationDetails } = useGetSingleConversationDetails({
    conversationId: conversationId!,
    enabled: !!conversationId,
  });
  const { data: conversationData } = useGetConversationMessages({
    conversationId,
  });

  const reasoningTasks = conversationData?.messages.find(m => m._id === messageForReasoningPreview)?.tasks;

  const agentIds = useMemo(() => conversationDetails?.bots.map(bot => bot._id) ?? [], [conversationDetails]);

  const allAgentResults = useQueries({
    queries: agentIds.map(id => ({
      queryKey: singlePrivateAgentWithConfigKeys.id(id),
      queryFn: () => getSingleConversationAgentWithConfig(id),
    })),
  });

  const agents = useMemo(() => allAgentResults.map(r => r.data).filter(Boolean), [allAgentResults]);

  const isLoading = allAgentResults.some(r => r.isPending) || isPendingConversationDetails;
  const isError = allAgentResults.some(r => !!r.error);

  useTrackAgentInteractionStats({ agentIds: conversationAgentIds });

  if (isMobile) {
    return (
      <>
        <Chat
          agents={agents}
          isLoading={isLoading}
          isError={isError}
          onOpenMobileBookmarksHistoryPanel={() => setIsMobileBookmarkHistoryPanelOpen(true)}
        />
        <div
          className={cn("fixed right-0 top-[72px] z-40 h-full w-full", {
            "-right-full": !isMobileBookmarkHistoryPanelOpen,
          })}
        >
          <BookmarksHistoryPanel onClosePanel={() => setIsMobileBookmarkHistoryPanelOpen(false)} />
        </div>
      </>
    );
  }

  return (
    <>
      <Chat agents={agents} isLoading={isLoading} isError={isError} />
      <AnimatedBookmarksHistoryWrapper>
        {reasoningTasks?.length ? <ChatMessageReasoning tasks={reasoningTasks} /> : <BookmarksHistoryPanel />}
      </AnimatedBookmarksHistoryWrapper>
    </>
  );
};

export default Conversation;
