import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { cn } from "@/lib/utils";
import type { HierarchyTask } from "@/types/conversation";
import { TaskType } from "@/types/conversation";

export const ChatMessageReasoning = ({ tasks }: { tasks: HierarchyTask[] }) => {
  const { setMessageForReasoningPreview, conversationDetails } = useChatContext();

  const taskSymbolMap = {
    [TaskType.ACTION]: "⚙️",
    [TaskType.DELEGATE]: "🗣️",
    [TaskType.THINK]: "💭",
    [TaskType.REPORT]: "📝",
  };

  const delegateAgentInfo = (findId: string) => {
    const agentInfo = conversationDetails?.bots.find(bot => bot._id === findId);

    return <span>{agentInfo?.name}</span>;
  };

  return (
    <div
      className={cn(
        "z-50 h-full w-full lg:min-w-[20rem] lg:max-w-[20rem] min-[1150px]:max-w-[24rem] xl:min-w-[32rem] xl:max-w-[32rem]",
        "flex flex-col border-l border-neutral-200"
      )}
    >
      <div className="flex justify-between p-4">
        <h3 className="text-2xl font-bold leading-8 text-neutral-750">Reasoning</h3>
        <IconButton
          variant="tertiary"
          size="small"
          icon={<Icons.Close />}
          onClick={() => setMessageForReasoningPreview("")}
        />
      </div>

      <div className="flex flex-col gap-2 overflow-y-auto p-4">
        {tasks.map((task, index) => {
          return (
            <div key={`task_${index}`}>
              {taskSymbolMap[task.taskType]}{" "}
              <span className="text-sm text-blue-500">
                {task.taskType === TaskType.THINK || TaskType.REPORT ? delegateAgentInfo(task.name) : task.name}
              </span>{" "}
              <span className="text-sm text-yellow-500">{task.input}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
