import { Avatar } from "@/components/Avatar";
import { NavigationProfileSections } from "@/components/Profiles/NavigationProfileSection";
import { Icons } from "@/components/ui/icons";
import { useGetUser } from "@/data/queries/useGetUser";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";
import { useGetWorkspaceUsers } from "@/data/queries/workspace/useGetWorkspaceUsers";

type SideBarNavUserInfoProps = {
  isGuestUser: boolean;
};

export const SideBarNavUserInfo = ({ isGuestUser }: SideBarNavUserInfoProps) => {
  const { data: user } = useGetUser({ enabled: !isGuestUser });
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const isNotPhone = useTailwindMediaQuery("sm");
  const { navigationSectionOpen } = useLayoutContext();
  const { data: members } = useGetWorkspaceUsers();

  const nameToDisplay = user?.name + (user?.lastName ? ` ${user.lastName}` : "");

  return (
    <div className="flex flex-row items-center gap-2.5">
      <Avatar
        src={user?.avatar}
        name={user?.name}
        onClick={() =>
          setNavigationProfileOpen({
            open: true,
            section: isNotPhone ? NavigationProfileSections.ACCOUNT.ACCOUNT : null,
          })
        }
        className="size-8 cursor-pointer rounded-full lg:size-10"
        size="custom"
      />
      {navigationSectionOpen && (
        <div className="flex flex-col">
          <p className="text-sm font-medium text-neutral-600">{nameToDisplay}</p>

          <div className="flex items-center gap-1 text-xxs font-medium text-neutral-400">
            <Icons.Team className="size-2.5" />
            {members?.length || 0}
          </div>
        </div>
      )}
    </div>
  );
};
