import { Avatar } from "@/components/Avatar";
import { GeneratedImage } from "@/components/ui/GeneratedImage";
import { cn } from "@/lib/utils";
import { useGetUser } from "@/data/queries/useGetUser";
import { memo } from "react";

import { ActionLoader } from "../ActionLoader";
import { ContactForm } from "../ContactForm";
import { MessageLoader } from "../MessageLoader";
import { References } from "../References";
import { VoteMessage } from "../VoteMessage";
import { VoiceMessage } from "../VoiceMessage";
import { MessageCloudCOT } from "./MessageCloudCOT";
import { MessageCloudCreatedAt } from "./MessageCloudCreatedAt";
import { MessageCloudOuterWrapper } from "./MessageCloudOuterWrapper";
import { MessageCloudMarkdown } from "./MessageCloudMarkdown";

import type { UploadChatFileResponse } from "@/data/mutations/useUploadFileToConversation";
import type { Message, HierarchyTask } from "@/types/conversation";
import type { Trace } from "@/types/trace";
import type { EmbedKnowledges } from "@/types/agent";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { MESSAGE_HIGHLIGHT_CLASS } from "../../contants/chat-contants";
import { MessageCloudContentWrapper } from "./MessageCloudContentWrapper";
import { MessageCloudAttachments } from "./MessageCloudAttachments";
import { LinkButton } from "@/components/ui/link-button";
import { Icons } from "@/components/ui/icons";

export type MessageCloudProps = {
  message: Message["text"];
  isBotMessage: boolean;
  vote: Message["vote"];
  messageId?: Message["_id"];
  messageIndex?: number;
  conversationId?: Message["conversationId"];
  botNames: string[];
  createdAt?: Message["createdAt"];
  trace?: Trace | null;
  isBookmark: Message["isBookmark"];
  agent?: {
    _id: string;
    name: string;
    avatar: string;
    creator?: string;
  };
  isGeneratingBotResponse?: boolean;
  attachments?: UploadChatFileResponse[];
  messageUser?: Message["user"];
  disableActions?: boolean;
  audio?: Message["audio"];
  className?: string;
  displayUserDetails?: {
    name: string;
    avatar: string;
  };
  cot?: string;
  tasks?: HierarchyTask[];
  showAgentName?: boolean;
  setDocumentInPreview: React.Dispatch<React.SetStateAction<EmbedKnowledges | null>> | undefined;
  setDocPreviewPage: React.Dispatch<React.SetStateAction<number | null>> | undefined;
};

const _MessageCloud = ({
  message,
  messageId,
  isBotMessage,
  trace,
  vote,
  conversationId,
  createdAt,
  messageIndex,
  isBookmark,
  agent,
  botNames,
  isGeneratingBotResponse,
  cot,
  tasks,
  attachments,
  messageUser,
  disableActions,
  audio,
  displayUserDetails,
  showAgentName,
  setDocumentInPreview,
  setDocPreviewPage,
}: MessageCloudProps) => {
  const { agentMode, setMessageForReasoningPreview } = useChatContext();
  const { user } = useGetUser();

  if (!isBotMessage) {
    if (audio?.base64 || audio?.blob || audio?.url) {
      return (
        <MessageCloudOuterWrapper id={messageId}>
          <Avatar name={user?.name} src={user?.avatar} className="border border-neutral-200" />
          <MessageCloudContentWrapper>
            <VoiceMessage messageId={messageId ?? ""} conversationId={conversationId ?? ""} audio={audio} />
            {createdAt && <MessageCloudCreatedAt createdAt={createdAt} />}
          </MessageCloudContentWrapper>
        </MessageCloudOuterWrapper>
      );
    }
    const botTags = botNames.map(name => `@${name}`);

    const getMessageWithTags = ({ message, botTags }: { message: string; botTags: string[] }) => {
      const parts = message.split(new RegExp(`(${botTags.join("|")})`, "gi"));
      return parts.map((part, index) => {
        if (botTags.includes(part)) {
          return (
            <span key={index} className="bg-focus-bg px-0.5 py-1 font-semibold text-focus">
              {part}
            </span>
          );
        } else {
          return part;
        }
      });
    };

    const isUserMe = user?._id === messageUser;

    const getUserAvatar = () => {
      if (displayUserDetails) {
        return <Avatar name={displayUserDetails.name} src={displayUserDetails.avatar} />;
      }
      return <Avatar name={isUserMe ? user?.name : "User"} src={isUserMe ? user?.avatar : undefined} />;
    };

    return (
      <MessageCloudOuterWrapper id={messageId}>
        {getUserAvatar()}
        <MessageCloudContentWrapper
          className={cn(
            "whitespace-pre-wrap break-words text-sm font-medium text-primary-black",
            MESSAGE_HIGHLIGHT_CLASS
          )}
        >
          {getMessageWithTags({ message, botTags })}
          {!!attachments?.length && <MessageCloudAttachments attachments={attachments} />}
          {createdAt && <MessageCloudCreatedAt createdAt={createdAt} />}
        </MessageCloudContentWrapper>
      </MessageCloudOuterWrapper>
    );
  }

  if (isGeneratingBotResponse && agent) {
    return <MessageLoader agent={agent} withDelay />;
  }

  const showContactFormIntents =
    trace?.meta?.intent === "want real person or phone call support" ||
    trace?.meta?.intent === "want to make a purchase";

  const showContactForm =
    showContactFormIntents && !!conversationId && typeof messageIndex === "number" && messageIndex < 4;

  const filteredReferences = trace?.reference?.map(item => ({
    ...item,
    ["source"]: "",
  }));

  const traceFunction = trace?.meta?.function?.name ? trace?.meta?.function : null;
  const chainOfActions = trace?.chain_of_actions || [];
  // Update: to support multiple function calls, we need to use chain of action list instead of single trace function
  if (traceFunction && chainOfActions?.length === 0) {
    chainOfActions.push(traceFunction);
  }

  const useFilteredReferences = !(agent?.creator && user && user._id === agent.creator);

  const showActionLoader = trace?.actionType && trace.genImage?.status !== "success";
  const showVoteMessage = !trace?.actionType && messageId && !disableActions;
  const showReferences =
    (trace?.reference || traceFunction || chainOfActions) && !showContactForm && !useFilteredReferences;

  return (
    <MessageCloudOuterWrapper id={messageId} className="relative">
      <Avatar name={agent?.name} src={agent?.avatar} />

      <MessageCloudContentWrapper>
        {showAgentName && !cot && <p className="text-sm font-bold leading-[22px] text-neutral-750">{agent?.name}</p>}

        {!!cot && (
          <MessageCloudCOT
            cot={cot}
            isLoading={!message && trace?.genImage?.status !== "success"}
            agentName={showAgentName ? agent?.name : undefined}
          />
        )}

        {!!tasks?.length && (
          <LinkButton
            className="absolute right-4 top-7 flex gap-1"
            onClick={() => setMessageForReasoningPreview(messageId ?? "")}
          >
            Reasoning
            <Icons.Arrow className="size-4 rotate-[135deg]" />
          </LinkButton>
        )}

        {!!message && <MessageCloudMarkdown message={message} />}

        {trace?.genImage?.status && <GeneratedImage genImage={trace.genImage} labelClassName="text-sm" />}

        {showActionLoader && <ActionLoader action={trace.actionType!} actionObject={trace.otherActionObject} />}

        {showVoteMessage && (
          <VoteMessage
            messageId={messageId}
            conversationId={conversationId}
            agentId={agent?._id}
            vote={vote}
            message={trace?.genImage?.prompt || message}
            isBookmark={isBookmark}
          />
        )}

        {showReferences && (
          <References
            agentMode={agentMode}
            references={trace?.reference || []}
            chainOfActions={chainOfActions}
            setDocumentInPreview={setDocumentInPreview}
            setDocPreviewPage={setDocPreviewPage}
          />
        )}

        {createdAt && <MessageCloudCreatedAt createdAt={createdAt} />}

        {filteredReferences && !showContactForm && useFilteredReferences && (
          <References
            agentMode={agentMode}
            references={filteredReferences}
            chainOfActions={chainOfActions}
            setDocumentInPreview={setDocumentInPreview}
            setDocPreviewPage={setDocPreviewPage}
          />
        )}
        {showContactForm && <ContactForm conversationId={conversationId} />}
      </MessageCloudContentWrapper>
    </MessageCloudOuterWrapper>
  );
};

export const MessageCloud = memo(_MessageCloud);
