import type { AgentNotification } from "@/types/notification";
import { NotificationReference } from "@/types/notification";
import { calculateNotificationsTimeDifference } from "@/utils/calculateNotificationsTimeDifference";
import { Link } from "react-router-dom";
import { LinkButton } from "@/components/ui/link-button";
import { useSubscriptionTierDialogContext } from "@/contexts/SubscriptionTierDialogContext/useSubscriptionTierDialogContext";
import { Button } from "@/components/ui/button";

type NotificationDescriptionProps = {
  notification: AgentNotification;
};

export const NotificationDescription = ({
  notification: { title, subtitle, date, entityName, referenceType, url },
}: NotificationDescriptionProps) => {
  const { openDialog: openSubscriptionTierDialog } = useSubscriptionTierDialogContext();

  const notificationType =
    Object.keys(NotificationReference)[Object.values(NotificationReference)?.indexOf(referenceType)]?.toLowerCase();
  const showUpgradeLink = referenceType === NotificationReference.SUBSCRIPTION;
  const showTitle = title !== entityName;
  const showLeadLink = referenceType === NotificationReference.LEAD && !!url;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1 text-sm">
        {showTitle && <span className="font-semibold">{title}</span>}
        <span className={showTitle ? "lowercase " : "font-semibold"}>
          {subtitle}
          {showLeadLink && (
            <>
              {"  "}
              <Link to={url}>
                <LinkButton size="sm" className="text-sm text-primary-400">
                  Open Lead Details
                </LinkButton>
              </Link>
            </>
          )}
        </span>
      </div>
      <div className="flex items-center gap-2">
        <div className="text-xs text-gray-400">{calculateNotificationsTimeDifference(date)}</div>
        <div className="h-1 w-1 rounded-full bg-neutral-400" />
        <div className="text-xs capitalize text-gray-400">{notificationType}</div>
      </div>
      {showUpgradeLink && (
        <div className="pt-1">
          <Button onClick={() => openSubscriptionTierDialog()}>Upgrade</Button>
        </div>
      )}
    </div>
  );
};
