export const quickLinks = [
  {
    emoji: "🌟",
    title: "Create New Agents",
    link: "https://www.agentx.so/agentx-tutorial/how-to-build-an-ai-agent-beginners-guide",
  },
  {
    emoji: "🌐",
    title: "Publish to Website",
    link: "https://www.agentx.so/agentx-tutorial/how-to-deploy-an-ai-agent-to-your-website",
  },
  {
    emoji: "📚",
    title: "How to Add Knowledge",
    link: "https://www.agentx.so/agentx-tutorial/how-to-create-a-knowledge-base",
  },
  {
    emoji: "💬",
    title: "Create Group Chat",
    link: "https://www.agentx.so/post/how-to-create-a-group-chat-with-gpt-4o-and-claude-3-5-and-more",
  },
  {
    emoji: "🛂",
    title: "Build Lead Generation",
    link: "https://www.agentx.so/post/how-to-create-a-lead-generation-ai-agent-chatbot-on-agentx---formless-and-straight-to-your-mailbox",
  },
];

export const tutorialsLinks = [
  {
    title: "How to deploy and publish an agent to your website?",
    link: "https://www.agentx.so/agentx-tutorial/how-to-deploy-an-ai-agent-to-your-website",
  },
  {
    title: "How to add knowledge to my AI Agent?",
    link: "https://www.agentx.so/agentx-tutorial/how-to-create-a-knowledge-base",
  },
  {
    title: "How to integrate AgentX with Zapier?",
    link: "https://www.agentx.so/agentx-tutorial/zapier-integration-overview",
  },
  {
    title: "How to create a formless Lead Generation agent?",
    link: "https://www.agentx.so/post/how-to-create-a-lead-generation-ai-agent-chatbot-on-agentx---formless-and-straight-to-your-mailbox",
  },
  {
    title: "How to create an AI group chat?",
    link: "https://www.agentx.so/post/how-to-create-a-group-chat-with-gpt-4o-and-claude-3-5-and-more",
  },
  {
    title: "Join the waitlist for our upcoming e-commerce AI release.",
    link: "https://www.agentx.so/e-commerce",
  },
];
