import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icons";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { NavigationProfileSections } from "@/components/Profiles/NavigationProfileSection";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";
import { Button } from "@/components/ui/button";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";

export const InviteTeamMemberNavBtn = ({ isGuestUser }: { isGuestUser: boolean }) => {
  const { showLoginDialog } = useLoginDialogContext();
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const { navigationSectionOpen } = useLayoutContext();

  const handleNavigationProfileOpen = () => {
    if (isGuestUser) {
      showLoginDialog();
    } else {
      setNavigationProfileOpen({ open: true, section: NavigationProfileSections.WORKSPACES.TEAM });
    }
  };

  return (
    <Button
      variant="ghost"
      className={cn(
        "h-[38px] w-full bg-white p-0 text-center text-primary-400 hover:bg-primary-100",
        !navigationSectionOpen && "w-[38px]"
      )}
      onClick={handleNavigationProfileOpen}
    >
      <Icons.InvitePeople className={cn("size-4", !navigationSectionOpen && "size-6")} />
      {navigationSectionOpen && <div className="hidden whitespace-nowrap pl-2 text-xs text-black lg:block">Invite</div>}
    </Button>
  );
};
