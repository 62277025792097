import { NotificationAvatar } from "@/components/Notifications/UngroupedNotification/NotificationAvatar";
import { NotificationDescription } from "@/components/Notifications/UngroupedNotification/NotificationDescription";
import { NotificationMenu } from "../NotificationMenu";

import type { AgentNotification } from "@/types/notification";
import { cn } from "@/lib/utils";

type NotificationItemProps = {
  notification: AgentNotification;
};

export const NotificationItem = ({ notification }: NotificationItemProps) => {
  const { read } = notification;

  // TODO: accept/decline action handlers
  // const showAcceptDeclineButtons = !!notification.approveAction && !!notification.declineAction;

  return (
    <div
      className={cn(
        "flex items-center justify-between gap-2 border-b border-gray-200 px-1 py-4",
        !read && "bg-primary-50"
      )}
    >
      <div className="flex items-center gap-2">
        <NotificationAvatar
          isRead={notification.read}
          imageSrc={notification?.imgSrc}
          isPersonNotification={!!notification?.entityId}
        />
        <NotificationDescription notification={notification} />
      </div>
      <div className="h-full px-4 py-2">
        <NotificationMenu notification={notification} />
      </div>

      {/* TODO: accept/decline action handlers */}
      {/* showAcceptDeclineButtons && (
        <>
          <Button loading={} onClick={}>
            Accept
          </Button>
          <Button loading={} onClick={}>
            Decline
          </Button>
        <>
      )} */}
    </div>
  );
};
