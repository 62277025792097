import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";
import type { Agent, FullAgent } from "@/types/agent";
import { createAgentPayload } from "@/utils/updateAgentPayload";
import { agentsKeys } from "@/data/queries/useGetAgents";
import { useGetUser } from "../queries/useGetUser";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import { agentsWithConfigKeys } from "../queries/useGetAgentsWithConfig";
import { workspaceAgentsKeys } from "../queries/workspace/useGetWorkspaceAgents";
import { userAgentsKeys } from "../queries/useGetUserAgents";
import { notificationsKeys } from "@/data/queries/notifications/useGetNotifications";

export const useCreateAgent = () => {
  const { user } = useGetUser();
  const queryClient = useQueryClient();
  const { openDialog } = useAccountUsageDialogContext();

  const createAgent = async (newAgentData: FullAgent & { loadJs: boolean }) => {
    const payload = createAgentPayload(newAgentData, user);

    const { data } = await restClient.post<Agent>(apiPaths.createAgent, payload);
    return data;
  };

  const mutation = useMutation<Agent, AxiosError<{ message: string }>, FullAgent & { loadJs: boolean }>({
    mutationFn: createAgent,
    onSuccess: () => {
      void queryClient.refetchQueries({ queryKey: agentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: agentsWithConfigKeys.all });
      void queryClient.invalidateQueries({ queryKey: workspaceAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: userAgentsKeys.all });
      void queryClient.invalidateQueries({ queryKey: notificationsKeys.all });
    },
    onError: error => {
      const status = error.response?.status;

      if (status === 429) {
        openDialog("agentCreate");
      }
    },
  });

  return mutation;
};
