import { WorkspaceMyAgents } from "@/pages/Workspace/components/WorkspaceMyAgents";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { FetchNextPageTrigger } from "@/components/Chat/components/FetchNextPageTrigger";
import { useGetAgentsWithConfig } from "@/data/queries/useGetAgentsWithConfig";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { IconButton } from "@/components/ui/icon-button";
import { Icons } from "@/components/ui/icons";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants/routes";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { useNavigate } from "react-router";
import { useState } from "react";
import MultiAgentsChatDialog from "@/components/dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import type { Agent } from "@/types/agent";

export const MyAgentsSpace = () => {
  const navigate = useNavigate();
  const {
    data: myAgents,
    isPending: isMyAgentsWithConfigPending,
    isSuccess: isMyAgentsWithConfigSuccess,
    fetchNextPage: myAgentsFetchNextPage,
    hasNextPage: myAgentsHasNextPage,
    isFetchingNextPage: isMyAgentsFetchingNextPage,
  } = useGetAgentsWithConfig();

  const isScreenSm = !useTailwindMediaQuery("sm");

  const [isMultiAgentsChatDialogOpen, setIsMultiAgentsChatDialogOpen] = useState(false);

  const handleCreateMultiAgentsChat = ({ agentIds, manager }: { agentIds: string[]; manager: Agent["_id"] }) => {
    navigate(getNewConversationRoute(agentIds, manager));
  };

  return (
    <>
      <h4 className="sticky top-0 z-10 flex w-full items-center justify-between border-t border-t-primary-200 bg-fade-out-vertical-primary px-6 py-4 text-left font-bold">
        My Agents
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            {isScreenSm ? (
              <IconButton icon={<Icons.Plus />} size="medium" />
            ) : (
              <Button prefixIcon={<Icons.Plus />}>New</Button>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="flex flex-col gap-1 p-1.5">
            <DropdownMenuItem
              className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
              onClick={() => navigate(ROUTES.createAgent)}
            >
              <Icons.Bot /> Create Agent
            </DropdownMenuItem>

            <DropdownMenuItem
              className="flex items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
              onClick={() => setIsMultiAgentsChatDialogOpen(true)}
            >
              <Icons.AddGroup /> Multi Agents Chat
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </h4>

      <WorkspaceMyAgents
        agents={myAgents ?? []}
        isLoading={isMyAgentsWithConfigPending}
        isSuccess={isMyAgentsWithConfigSuccess}
        canEdit
        canTransferOwnership={false}
      />

      <MultiAgentsChatDialog
        isOpen={isMultiAgentsChatDialogOpen}
        setIsOpen={setIsMultiAgentsChatDialogOpen}
        onSubmit={handleCreateMultiAgentsChat}
        headerText="Select Agents to Create Group Chat"
      />

      {isMyAgentsFetchingNextPage && <LoadingSpinner className="my-5 flex items-center justify-center" />}

      {myAgentsHasNextPage && <FetchNextPageTrigger fetchNextPage={myAgentsFetchNextPage} />}
    </>
  );
};
