import { Icons } from "@/components/ui/icons";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import WorkspaceWhatsNew from "./components/WorkspaceWhatsNew";
import { ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { WorkspaceBaseModels } from "./components/WorkspaceBaseModels";
import { useGetWorkspaceBasicAgents } from "@/data/queries/workspace/useGetWorkspaceBasicAgents";
import { CustomerSpace } from "@/pages/Workspace/components/CustomerSpace/CustomerSpace";
import { MyAgentsSpace } from "@/pages/Workspace/components/MyAgentsSpace";
import { useGetUser } from "@/data/queries/useGetUser";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { useLayoutContext } from "@/contexts/LayoutContext/useLayoutContext";

export const Workspace = () => {
  const { data: basicAgents, isPending: isAgentBasicPending } = useGetWorkspaceBasicAgents();
  const { data: user } = useGetUser();
  const { quickLinksSectionOpen, toggleQuickLinksSectionOpen } = useLayoutContext();

  const isDesktop = useTailwindMediaQuery("lg");
  const isCustomerUser = !!user?.customer;
  const nameToDisplay = user?.name + (user?.lastName ? ` ${user.lastName}` : "");

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel defaultSize={70} minSize={30}>
        <div className="flex h-full flex-1 flex-col text-center">
          <div className="relative mb-6 flex items-center justify-between px-6 pt-4">
            <div className="col-span-2 md:col-span-1">
              <h1 className="text-start text-[1.75rem] font-bold leading-9">Hey, {nameToDisplay}👋</h1>
              <p className="text-start text-sm leading-[1.375rem] text-secondary-400">
                Here is your workspace to build new agents
              </p>
            </div>

            {isDesktop && (
              <motion.button onClick={toggleQuickLinksSectionOpen} whileTap={{ y: 1, rotate: 180 }}>
                <Icons.Fold className={cn("size-6", !quickLinksSectionOpen && "rotate-180")} />
              </motion.button>
            )}
          </div>

          <WorkspaceBaseModels agents={basicAgents ?? []} isLoading={isAgentBasicPending} />

          <div className="h-full overflow-y-scroll bg-[#FCFAFF] pb-6">
            <MyAgentsSpace />
            {isCustomerUser && <CustomerSpace />}
          </div>
        </div>
      </ResizablePanel>

      {isDesktop && (
        <AnimatePresence initial={false}>
          {quickLinksSectionOpen ? (
            <motion.div
              initial={{ width: 0 }}
              transition={{ duration: 0.1 }}
              animate={{ width: "30%" }}
              exit={{ width: 0 }}
              key="box"
            >
              <div className="min-w-30 h-full">
                <WorkspaceWhatsNew />
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
      )}
    </ResizablePanelGroup>
  );
};
